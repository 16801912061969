@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/open-sans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/open-sans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/open-sans/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
